import React from "react";
import { Route, Routes } from "react-router-dom";

//system
import { ConfirmationPage } from "pages/profile/confirmation";
import { ConfirmResetPage } from "pages/confirmReset";
import { LoginPage } from "pages/login";
//pages
import { NotFoundPage } from "pages/system/not_found";
import { EntryPage } from "pages/system/entry";
import { ProfilePage } from "pages/profile";

import { SourcesPage } from "pages/sources";
import { SourcesFilterPage } from "pages/sources_seller_filter";

import { CompaniesPage } from "pages/companies";
import { MembersPage } from "pages/companies_members";

//doc_pages
import { DetailsPage } from "pages/doc_details";
import { ChequesMap } from "pages/doc_cheques_map";
import { ChequesList } from "pages/doc_cheques_list";
import { DocumentsMap } from "pages/doc_documents_map";
import { DocumentsList } from "pages/doc_documents_list";

//components
import { ProtectedLayout } from "widgets/layouts";
import { Login, Register, Restore } from "widgets/enter";
import { Navbar } from "widgets/navbar";
import { TestPage } from "../pages/test";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />}>
        <Route index element={<Login />}/>
        <Route path="register" element={<Register />}/>
        <Route path="restore" element={<Restore />}/>
      </Route>

      <Route path="/confirmation" element={<ConfirmationPage />} />
      <Route path="/reset" element={<ConfirmResetPage />} />
      <Route path="/" element={<EntryPage />} />
      <Route element={<ProtectedLayout navbar={<Navbar />} />}>
        <Route path="/profile" element={<ProfilePage />} />

        <Route path="/companies">
          <Route index element={<CompaniesPage />} />
          <Route path=":companyId/members" element={<MembersPage />} />
        </Route>

        <Route path="/sources">
          <Route index element={<SourcesPage />} />
          <Route path="filter/:sourceId" element={<SourcesFilterPage />} />
        </Route>

        <Route path="/documents">
          {/*documents*/}
          <Route element={<DocumentsList />} path="docList" />
          <Route element={<DocumentsMap />} path="docMap" />
          {/*cheques*/}
          <Route element={<ChequesList />} path="chequesList" />
          <Route element={<ChequesMap />} path="chequesMap" />
          {/*common*/}
          <Route element={<DetailsPage />} path=":id" />
          {/*system*/}
          <Route element={<NotFoundPage />} index />
        </Route>

        {/*test*/}
        <Route element={<TestPage />} path="/test/:id" />

      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};