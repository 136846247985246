import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, VStack } from "@chakra-ui/react";
import { ISource } from "entities/sources";

//module
import SourceFormPass from "./pass/source_form_pass";
import SourceFormEds from "./eds/source_form_eds";
import { useCreateUpdateSource } from "../model/useCreateUpdateSource";
import { useCreateUpdateSourceEds } from "../model/useCreateUpdateSourceEds";

interface IProps {
  source?: ISource | undefined;
  disclosure?: ReturnType<typeof useDisclosure>;
  sideEffect?: () => void
  direction?: "from" | "to";
}

export function SourceForm(
  {
    source,
    disclosure,
    sideEffect,
    direction,
  }: IProps) {

  // const { sourceModal } = useSourcesContext();
  const { createUpdateSource, isLoading } = useCreateUpdateSource({ source });
  const { createUpdateSourceEds, isLoading: isLoadingEds } = useCreateUpdateSourceEds({ source });
  const onCreateUpdateSource = async (source: ISource, shouldFetchSource: boolean = true) => {
    const res = await createUpdateSource(source, shouldFetchSource);
    sideEffect?.()
    if (res) disclosure?.onClose();
  };

  const onCreateUpdateSourceEds = async (source: ISource, shouldFetchSource: boolean = true) => {
    const res = await createUpdateSourceEds(source, shouldFetchSource);
    sideEffect?.()
    if (res) disclosure?.onClose();
  }

  const isEDSDisabled = () => {
    if (direction === "to") return true;
    if (source?.authenticateType === 4) return true;
    return false;
  }

  const isPassDisabled = () => {
    if (source?.authenticateType === 8) return true;
    return false;
  }

  return (
    <>
      <Tabs
        // isLazy={true}
        defaultIndex={source?.authenticateType === 8 ? 1 : 0}
        variant={"form"}
        w={"full"}
      >
        <TabList
          w={"full"}
          display={"grid"}
          gridTemplateColumns={"1fr 1fr"}
        >
          <Tab
            isDisabled={isPassDisabled()}
          >
            Пароль
          </Tab>
          <Tab
            isDisabled={isEDSDisabled()}
          >
            ЭЦП
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack gap={"15px"}>
              <SourceFormPass source={source} onSubmit={onCreateUpdateSource} isLoading={isLoading} direction={direction} />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack gap={"15px"}>
              <SourceFormEds source={source} onSubmit={onCreateUpdateSourceEds} isLoading={isLoadingEds} />
            </VStack>
          </TabPanel>
        </TabPanels>

      </Tabs>
    </>
  );
}