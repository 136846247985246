import { Button, Text } from "@chakra-ui/react";

export default function Greetings({ nextStep }: { nextStep: () => void }) {
  const onClick = () => nextStep();
  return (
    <>
      <form style={{ display: "contents" }}>
          <Text pb={"15px"} variant={"heading"}> Добро пожаловать </Text>
          <Text variant={"descriptor"}>Наше приложение позволит Вам обмениваться документами между СБИС и IIKO в пару кликов</Text>
          <Button mt={"40px"} fontSize={"md"} w={"full"} h={"60px"} onClick={onClick}>
            Начать работу
          </Button>
      </form>
    </>
  );
}