import React, { useCallback, useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Box,
  Button,
  FlexProps,
  Grid,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { IOverride, useGetDocumentsOverridesQuery } from "api";

import { useAppSelector } from "shared/model";
import { ButtonPanelWrapper, PageWithHeading, WithLoader } from "shared/ui";

import { ISource, useGetSources } from "entities/sources";

//module
import { StocksOverrides } from "./stocks";
import { SuppliersOverrides } from "./suppliers";
import { ProductsOverrides } from "./products";

const defaultSource: ISource = {
  id: "",
  name: "",
  company: "",
  type: 0,
  authenticateType: 0,
  status: "NotAuth",
};

//TODO между Overrides и Details ООООООЧЕнь похожая логика

export function Overrides({
  documents,
  onSubmitted,
  onMapStateChanged,
  upload,
  isLoadingUpload,
}: {
  documents: string[];
  onSubmitted?: (items: IOverride[]) => void;
  onMapStateChanged?: (e: { isMapped: boolean }) => void;
  upload: () => void;
  isLoadingUpload?: boolean;
} & FlexProps) {
  const sourceId = useAppSelector((x) => x.sources.current);

  const { data: sources } = useGetSources();
  const targetSource =
    sources?.find((item) => item.id === sourceId) || defaultSource;

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const [changes, setChanges] = useState<IOverride[]>([]);

  const {
    data: overrides,
    refetch: refetchOverrides,
    isLoading,
  } = useGetDocumentsOverridesQuery(
    sourceId ? { documents, target: sourceId } : skipToken
  );

  const setOverride = useCallback((o: IOverride) => {
    setChanges((changes) => [
      ...(changes ? changes.filter((x) => x.original !== o.original) : []),
      ...[o],
    ]);
  }, []);

  //возвращает массив оверрайдов(отфильтрованный по типам), серверные оверрайды частично перезаписываются изменениями(changes)
  const getCombinedOverrides = useCallback(
    (...types: number[]) => {
      const names = changes.map((change) => change.original);
      return [
        ...(Array.isArray(overrides)
          ? overrides.filter(
              (x) => types.includes(x.type) && !names.includes(x.original)
            )
          : []),
        ...changes.filter((x) => types.includes(x.type)),
      ].sort((a, b) => a.original.localeCompare(b.original));
    },
    [overrides, changes]
  );

  //отправляем оверрайды на сервер
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      (async () => {
        if (onSubmitted) {
          await onSubmitted?.(changes);
          await refetchOverrides();
        }
      })();

      e.preventDefault();
      e.stopPropagation();
    },
    [changes, onSubmitted, refetchOverrides]
  );

  //сохраняем пустой массив сместо оверрайдов, хз где это используется
  const onReset = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      setChanges([]);
      if (onSubmitted) onSubmitted?.([]);
      e.preventDefault();
      e.stopPropagation();
    },
    [onSubmitted]
  );

  useEffect(() => {
    if (onMapStateChanged) {
      onMapStateChanged({
        isMapped: getCombinedOverrides(1, 2, 4).every((x) => !!x.value),
      });
    }
  }, [onMapStateChanged, getCombinedOverrides]);

  //TODO prodStock нужно false
  const doUpload = async () => {
    setIsLocalLoading(true);
    await onSubmitted?.(changes);
    await upload();
    setIsLocalLoading(false);
  };

  const tabPanelStyle = {
    p: "20px",
    overflow: "auto", //важно
  };

  return (
    <PageWithHeading label={"Множественные сопоставления"}>
      <WithLoader isLoading={isLoading}>
        <form style={{ display: "contents" }} {...{ onSubmit, onReset }}>
          <Grid gridTemplateRows={"1fr auto"} h={"full"}>
            <Tabs
              maxH={"full"}
              display={"grid"}
              gridTemplateRows={"auto 1fr auto"}
              overflow={"auto"}
            >
              <TabList p={"0 20px"} zIndex={10} backgroundColor={"main_bg"}>
                <HStack
                  backgroundColor={"primary_bg"}
                  p={"5px"}
                  borderRadius={"100px"}
                >
                  <Tab borderRadius={"100px"}>Товары</Tab>
                  <Tab borderRadius={"100px"}>Контрагенты</Tab>
                  <Tab borderRadius={"100px"}>Склады</Tab>
                </HStack>
              </TabList>

              <TabPanels display={"contents"}>
                <TabPanel {...tabPanelStyle}>
                  <Grid gridTemplateRows={"1fr"} maxH={"full"}>
                    <Box overflow={"auto"}>
                      <ProductsOverrides
                        target={targetSource}
                        data={getCombinedOverrides(1)}
                        onChanged={setOverride}
                      />
                    </Box>
                  </Grid>
                </TabPanel>
                <TabPanel {...tabPanelStyle}>
                  <Grid gridTemplateRows={"1fr"} maxH={"full"}>
                    <Box overflow={"auto"}>
                      <SuppliersOverrides
                        target={targetSource}
                        data={getCombinedOverrides(2)}
                        onChanged={setOverride}
                      />
                    </Box>
                  </Grid>
                </TabPanel>
                <TabPanel {...tabPanelStyle}>
                  <Grid gridTemplateRows={"1fr"} maxH={"full"}>
                    <Box overflow={"auto"}>
                      <StocksOverrides
                        target={targetSource}
                        data={getCombinedOverrides(4)}
                        onChanged={setOverride}
                      />
                    </Box>
                  </Grid>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <ButtonPanelWrapper>
              <HStack h={"full"} justifyContent={"end"} alignContent={"center"}>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isLocalLoading || isLoadingUpload}
                >
                  Сохранить
                </Button>
                <Spacer />
                <Button
                  colorScheme="blue"
                  onClick={doUpload}
                  isLoading={isLocalLoading || isLoadingUpload}
                >
                  Выгрузить
                </Button>
              </HStack>
            </ButtonPanelWrapper>
          </Grid>
        </form>
      </WithLoader>
    </PageWithHeading>
  );
}
